<template>
  <v-container>
    <eagle-form :form-key="formKey"></eagle-form>
  </v-container>
</template>

<script lang="babel" type="text/babel">
import formMixin from '@/components/form/mixins/formMixin'
import formConfig from './productFormConfig'
import discountMixins from '@/mixins/discount.js'
export default {
  mixins: [formMixin, discountMixins],
  data: () => ({
    formKey: 'product-form',
    meta: {},
    isSetted : false
  }),
  mounted() {
    this.meta.loadProductMenus = this.loadProductMenus;
    this.meta.loadProductUnits = this.loadProductUnits;
    this.meta.freightPriceList = this.freightPriceList;
  },
  methods: {
    async beforeFormInit() {
      await Promise.all([
        this.loadProductMenus(),
        this.loadProductUnits(),
        this.loadFreightPriceList()
      ])
    },
    async loadProductMenus() {
      let result = null
      try {
        result = await this.$api.collection.productMenuApi.index(this.providerId, {
          pager: false,
          sort: {
            created_at: 'desc',
          },
        })
      } catch (error) {
        console.warn(error)
      }
      this.meta.productMenus = result
    },
    async loadProductUnits() {
      let result = null
      try {
        result = await this.$api.collection.productUnitApi.index(this.providerId, {
          pager: false,
          sort: {
            created_at: 'desc',
          },
        })
      } catch (error) {
        console.warn(error)
      }
      this.meta.productUnits = result
    },
    async loadFreightPriceList() {
      this.meta.freightPriceList = [
        {id: "freezing", name: '冷凍'},
        {id: "refrigeration", name: '冷藏'},
        {id: "normal", name: '常溫'},
      ]
    },
    validateBeforeSave() {
      const configs = this.formData.discount_config
      if(!configs) return;

      const isValid = this.isValidDiscount(configs, this.formData.price)
          && this.isPriceLessThan50Characters(this.formData.price)
          && this.isPerShippingCountLessThan50Characters(this.formData.per_shipping_count)

      if(!isValid) return true
    },
    async customDefaultData() {
      let defaultFormData = {
        name: null,
        price: 1,
        per_shipping_count: 1,
        discount_type: 0,
        menus: [],
      }
      const cloneProductId = this.$route.query.clone
      if (cloneProductId) {
        try {
          const result = await this.$api.collection.productApi.readForEdit(cloneProductId)
          const vipPriceList = await this.fetchVipPriceData(cloneProductId)
          const product = this.getComputedReadData(Object.assign(result, { vipPriceList }))
          console.warn('clone product', product)
          defaultFormData = {
            name: product.name,
            description: product.description,
            price: product.price,
            per_shipping_count: product.per_shipping_count,
            calculate_unit: product.calculate_unit,
            discount_type: product.discount_type,
            menus: product.menus,
            discount_config: product.discount_config,
            photos: product.photos,
            shipping_unit: product.shipping_unit,
            status: product.status,
            vipPriceList: product.vipPriceList,
          }
        } catch(error) {
          console.warn(error)
          console.warn(`商品複製失敗, 商品 ${cloneProductId} 可能不存在`)
          this.$snotify.warning(`商品 ${cloneProductId} 可能不存在`, `商品複製失敗`)
        }
      }

      return defaultFormData
    },
    async fetchVipPriceData(target) {
      return await this.$api.collection.vipApi.readProductPrice(this.providerId, target)
    },
    async readApi(target) {
      console.log('readApi')
      const result = await this.$api.collection.productApi.readForEdit(target)
      const vipPriceList = await this.fetchVipPriceData(target)
      return this.getComputedReadData(Object.assign(result, { vipPriceList }))
    },
    getComputedReadData(product) {
      if(Array.isArray(product.categories)) product.categories = product.categories[0]
      else product.categories = null
      product.menus = product.menus.map(m => ({
        id: m.product_menu_id,
      }))

      if (this.meta.productUnits && !product.shipping_unit) {
        product.shipping_unit = this.meta.productUnits[0].name;
      }
      if (this.meta.productUnits && !product.calculate_unit) {
        product.calculate_unit = this.meta.productUnits[0].name;
      }
      if (this.meta.freightPriceList && product.temperature) {
        const selectedTemp = this.meta.freightPriceList.filter(v => v.id === product.temperature[0])
        product.temperature = selectedTemp[0] || this.meta.freightPriceList[0]
      }
      return product
    },
    async createApi(formData) {
      this.$root.gaLogEvent('供應商_點擊_建立商品')
      formData.temperature = [formData.temperature.id]
      formData.menus = formData.menus.map(m => ({
        product_menu_id: m.id,
      }))
      const product = await this.$api.collection.productApi.create(this.providerId, formData)
      await this.updateVip(formData, product.id)
      return product
    },
    async updateApi(target, formData) {
      this.$root.gaLogEvent('供應商_點擊_編輯商品')
      formData.menus = formData.menus.map(m => ({
        product_menu_id: m.id,
      }))
      formData.temperature = [formData.temperature.id]
      if(formData.menus.length > 0) this.$root.gaLogEvent('供應商_點擊_歸屬商品分類')
      if(!formData.categories) formData.categories = []
      else formData.categories = [formData.categories]
      await this.updateVip(formData, target)
      const result = await this.$api.collection.productApi.update(this.providerId, target, formData)
      const vipPriceList = await this.fetchVipPriceData(target)
      return this.getComputedReadData(Object.assign(result, { vipPriceList }))
    },
    async updateVip (formData, target) {
      const vip_rules = formData.vipList.filter(v => v.price > 0).map(v => {
        let returnObj = new Object()
        returnObj.vip_group_id = v.id
        returnObj.price = parseInt(v.price)
        returnObj.status = Boolean(v.isSelected)
        return returnObj
      })
      await this.$api.collection.productApi.updateDiscountType(this.providerId, target, {discount_type: formData.discount_type})
      await this.$api.collection.vipApi.addProductPrice(this.providerId, target, { vip_rules })
    },
    async deleteApi(target) {
      return await this.$api.collection.productApi.delete(this.providerId, target)
    },
    getFormConfig() {
      return formConfig
    },
    setParams(formData) {
      if(formData) formData.provider_id = this.providerId
      return formData;
    },
    isTheSame() {
      if(!this.formData) return ;
      const isSame = ( !this.shipping_unit && !this.calculate_unit) ?
        false :
        (this.shipping_unit == this.calculate_unit)

      if(isSame && !this.isSetted) {
        this.isSetted = true;
        const formData = this.$eagleLodash.cloneDeep(this.formData)
        formData.per_shipping_count = 1
        this.$store.dispatch(`form/${this.formKey}/setData`, formData)
      }
      else {
          if(!isSame) {
            this.isSetted = false ;
          }
      }
    },
    // async afterSaveSuccessfully(result) {
    //   try {
    //     await this.$api.collection.productApi.bindMenu(this.providerId, result.id, this.formData.menus)
    //   } catch(error) {
    //     console.warn(`綁定商品、商品分類api發生意外錯誤`)
    //     console.warn(error)
    //   }
    // },
  },
  computed: {
    frontendDomain() {
      return this.$store.getters['base/eagleEnv'].frontendDomain
    },
    productCategoryMaxDepth() {
      return this.$store.getters['base/productCategoryMaxDepth']
    },
    providerId() {
      return this.$store.getters['member/providerId']
    },
    shipping_unit() {
      if(!this.formData) return ''
      return this.formData.shipping_unit;
    },
    calculate_unit() {
      if(!this.formData) return ''
      return this.formData.calculate_unit
    },

  },

}
</script>

<style lang="sass" type="text/sass" scoped></style>
